
export const apiKeys = {
    /*
    captcha: '6LfIor8pAAAAAPpU7_JXFxWUHjbrtrN4fRzx6kqB',
    emailjs: 'X-21uQJDj0CYwA3eY',
    firebaseApiKey: 'AIzaSyDtLJGKPUdAfdSvitTj34svMlTAfJsOQM0',
    googleId: 'G-CKTXZG22QH',
    spotifyKeys: [
        {
            id: "1",
            profile: "ondrej",
            spotifyApiId: "b585a8a1fad74c32881d76e8e064a930",
            spotifyApiSecret: "ac5ef0d09a4e45bbb3b9705849356a4b",
        },
        {
            id: "2",
            profile: "admin grooove",
            spotifyApiId: "4dd1332f02cd4ef99524cdcb7e92acd4",
            spotifyApiSecret: "1c8c36e6441c432a8107d814a9109dce",
        },
        {
            id: "3",
            profile: "ondrej-2",
            spotifyApiId: "1f4be4aa30934f9bad9239746074e2f1",
            spotifyApiSecret: "227ea39d81c64003860e73c02f06e4b5",
        },
        {
            id: "4",
            profile: "ondrej-3",
            spotifyApiId: "7c8f88ad6aad499d806e25fafb0eb1bd",
            spotifyApiSecret: "73bf0abe7e374c9ab2416fdf07014cf3",
        },
        {
            id: "5",
            profile: "ondrej-4",
            spotifyApiId: "a23eeab2a7324ce4a6c3aebfca2681aa",
            spotifyApiSecret: "bd0094a894d04eeab4544bfff459cc4f",
        },
        {
            id: "6",
            profile: "ondrej-5",
            spotifyApiId: "af61a912360a4b388064b6f65132ea48",
            spotifyApiSecret: "5858226d832246f7b8281da7b10e4812",
        },
        {
            id: "7",
            profile: "admin grooove-2",
            spotifyApiId: "829ed4786ef54b8eaa0ff7fd6f279a10",
            spotifyApiSecret: "5df0e341af804f81b49171258b1772c8",
        },
        {
            id: "8",
            profile: "admin grooove-3",
            spotifyApiId: "58c9058bdfaf488f90cd17dce33fdc20",
            spotifyApiSecret: "160659fa949a4b75897b055f3b69d8a0",
        },    
        {
            id: "9",
            profile: "admin grooove-4",
            spotifyApiId: "66bfc6536a084df6a4cff03ebdb882da",
            spotifyApiSecret: "f0ccce2971094206a0c7f10465610f06",
        },
        {
            id: "10",
            profile: "admin grooove-5",
            spotifyApiId: "ef6036ffda9a4869bef7961220f21165",
            spotifyApiSecret: "d2d8a15a4d6d480bad3bd5a78512a4f2",
        },
        {
            id: "11",
            profile: "sofa lofi-1",
            spotifyApiId: "9283616f54c541e4995f3eebb242c900",
            spotifyApiSecret: "500320d5d9564aacb859ff48032184f7",
        },
        {
            id: "12",
            profile: "sofa lofi-2",
            spotifyApiId: "615c911c07d24d428dd21ae667bb8fb6",
            spotifyApiSecret: "090e51687b4544e4a289fdd514fa0489",
        },
        {
            id: "13",
            profile: "sofa lofi-3",
            spotifyApiId: "c79a4ac465a24df8860aeadce8009170",
            spotifyApiSecret: "9399d4c0665b4ddda109d27a5637ad52",
        },
        {
            id: "14",
            profile: "sofa lofi-4",
            spotifyApiId: "85b6570a23a740b9812e2e011db6425c",
            spotifyApiSecret: "6c33c8d0810144438ab200f2ec2354aa",
        },
        {
            id: "15",
            profile: "sofa lofi-5",
            spotifyApiId: "323d37a6fd6648de9866b4f1b91cc5d9",
            spotifyApiSecret: "2d6cbd18ec64411ba553a795671a00ef",
        },
        {
            id: "16",
            profile: "hustej nazef-1",
            spotifyApiId: "5afbae552d1240c18f46384b8d5eb4a3",
            spotifyApiSecret: "34e92e2f4ef64042aa048ff8d53924fb",
        },
        {
            id: "17",
            profile: "hustej nazef-2",
            spotifyApiId: "66c74ee7ba624686b66e97ec21c2e13e",
            spotifyApiSecret: "fe65008861e44f4b90d0b45e6b6d34eb",
        },
        {
            id: "18",
            profile: "hustej nazef-3",
            spotifyApiId: "c7d9815f5f0c45289212a252181a2428",
            spotifyApiSecret: "a07327608e9d46f8948bdaf6d1cde50d",
        },
        {
            id: "19",
            profile: "hustej nazef-4",
            spotifyApiId: "ea6a1e64dd8c4fa5810e164f79eb2eb4",
            spotifyApiSecret: "564ef668edee48e2ab198567cf7d766e",
        },
        {
            id: "20",
            profile: "hustej nazef-5",
            spotifyApiId: "d1672837ac3447eb859937401fd9a00d",
            spotifyApiSecret: "dcfcacd0b20b40198f6f01954234b533",
        },
    ],
    
    */
    
   
    captcha: process.env.REACT_APP_CAPCHA,
    emailjs: process.env.REACT_APP_EMAILJS_KEY,
    firebaseApiKey: process.env.REACT_APP_FIREBASE_KEY,
    googleId: process.env.REACT_APP_GOOGLE_ID,
    spotifyKeys: [

        {
            id: "1",
            profile: "ondrej",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET,
        }, 
        {
            id: "2",
            profile: "admin grooove",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_2,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_2,
        },
        {
            id: "3",
            profile: "ondrej-2",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_3,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_3,
        },
        {
            id: "4",
            profile: "ondrej-3",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_4,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_4,
        },
        {
            id: "5",
            profile: "ondrej-4",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_5,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_5,
        },
        {
            id: "6",
            profile: "ondrej-5",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_6,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_6,
        },
        {
            id: "7",
            profile: "admin grooove-2",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_7,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_7,
        },
        {
            id: "8",
            profile: "admin grooove-3",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_8,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_8,
        },
        {
            id: "9",
            profile: "admin grooove-4",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_9,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_9,
        },
        {
            id: "10",
            profile: "admin grooove-5",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_10,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_10,
        },
        {
            id: "11",
            profile: "sofa lofi-1",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_11,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_11,
        },
        {
            id: "12",
            profile: "sofa lofi-2",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_12,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_12,
        },
        {
            id: "13",
            profile: "sofa lofi-3",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_13,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_13,
        },
        {
            id: "14",
            profile: "sofa lofi-4",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_14,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_14,
        },
        {
            id: "15",
            profile: "sofa lofi-5",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_15,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_15,
        },
        {
            id: "16",
            profile: "hustej nazef-1",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_16,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_16,
        },
        {
            id: "17",
            profile: "hustej nazef-2",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_17,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_17,
        },
        {
            id: "18",
            profile: "hustej nazef-3",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_18,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_18,
        },
        {
            id: "19",
            profile: "hustej nazef-4",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_19,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_19,
        },
        {
            id: "20",
            profile: "hustej nazef-5",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_20,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_20,
        },
    ],
    
}




