

// HEADER
export const EnLanguage = {
    id: "en",
    text1: "Hey Immortalz!",
    text2: "You know drum & bass like nobody else and your taste is really worth it. It's time to make your voices heard again and decide which tracks have defined the year 2024 for you, the Immortalz!",
    text2_1: "Voting in first round has already ended.",
    text3: "Cast your vote in the poll for your favorite Drum & Bass track released in the year 2024.", 
    text4: "- In the first round of voting, you can vote for up to 10 tracks, and you can use the whisperer or write any track you want.",
    text5: "We will close the first round of voting on 21st of April.",
    text6: "- The second round will take place from 23rd of April to 8th of May.",
    text7: "By voting in each round, you’re also entering a draw to win one of five tickets to the summer Let It Roll festival.",
    text8: "The top 10 winning tracks will be announced live at Let It Roll 2025, during a show on Friday at the Main Stage.",

    text9: "Thank you, Immortalz! We can’t wait to see which tracks you’ll choose!",
    input1: "ARTIST NAME",
    input1Err: "*ARTIST NAME IS MANDATORY",
    btn1: "CONFIRM",
    btn2: "SELECT",
    input2: "TRACK",
    input2Err: "*TRACK IS MANDATORY",
    text10: "You vote for:",
    text11: "ADD NEW TRACK",
    input3: "YOUR NAME",
    input3Err: "*NAME IS MANDATORY",
    input3Err2: "*CHOOSE AT LEAST ONE TRACK",
    input3Err3: "*YOU CAN VOTE FOR A MAXIMUM OF 10 D&B TRACKS",
    input4: "YOUR EMAIL",
    input4Err: "*EMAIL IS MANDATORY",
    input4Err2: "*THIS DOESN'T LOOK LIKE A VALID EMAIL",
    input4Err3: "*YOU'VE ALREADY VOTED",
    text12: "I agree to subscribe to the Let It Roll newsletter.",
    text13: "SEND",
    text14: "SENDING...",
    text14_1: "You can vote for up to 10 drum & bass tracks released in 2024.",

    //SECOND ROUND
    text15: "Immortalz,",
    text16: "Your votes have decided which tracks made it to the second round!",
    text17: "We've narrowed it down to the top 50 tracks, and now it's your chance to make your voice heard once again. This time, you have the power to select up to 10 tracks that will thunder across the Main Stage in a spectacular show, rivaling the grandeur of our opening ceremony.",
    text18: "In the second round of voting, you can simply tick up to 10 drum & bass tunes that have defined your 2023.",
    text19: "Round two is open until Friday, May 24th! Don’t miss your chance to close out together Let It Roll 2024 with a show you have been part of!",
    text20: "By voting, you’re also entering a draw to win one of ten tickets to the summer Let It Roll festival.",
    text21: "Which tracks will rise to the top 10? The power is in your hands, Immortalz!",
    text22: "*tracks are sorted in random order",

    // COUNTDOWN
    text23: "Are you ready to vote for TOP 10 drum and bass tracks of 2024? It’s about time!",
    text24: "The TOP 10 Show is back! On the final day of Let It Roll 2025, we’ll bid farewell with a 30-minute showcase of the year’s most impactful drum and bass tracks, as voted by our fans – the Immortalz. It’s a celebration of the tracks that defined 2024, culminating in a spectacular AV show with fireworks at the Main Stage.",
    text25: "Let’s create another unforgettable moment again, with you, Immortalz, in our New Home!",
    text26: "days",
    text27: "hours",

}



export const CzLanguage = {
    id:"cz",
    text1: "Hey Immortalz!",
    text2: "Protože jste největší fanoušci drum & bassu a váš vkus stojí opravdu za to, nastal čas rozhodnout, které tracky za rok 2024 rezonovaly na scéně nejvíce!",
    text2_1: "Hlasování v prvním kole již skončilo.",
    text3: "Hlasujte v anketě o nejlepší drum & bassový track, který vyšel v roce 2024.",   
    text4: "- V prvním kole hlasování lze hlasovat až pro 10 skladeb a můžete využít našeptávač, nebo napsat jakýkoliv track, co vás napadne.", 
    text5: "První kolo hlasování uzavřeme 21. dubna.",
    text6: "- Druhé kolo hlasování proběhne od 23. dubna do 8 května.",
    text7: "Díky hlasování v každém kole se automaticky zapojujete do slosování o jednu z pěti vstupenek na letní Let It Roll.",
    text8: "Vyhlášení top 10 vítězných tracků proběhne přímo na Let It Rollu 2025 prostřednictvím festivalové show, která proběhne v pátek na Main stage.",
    text9: "Děkujeme moc, Immortalz! Těšíme se na tracky, které vyberete!",
    input1: "JMÉNO UMĚLCE",
    input1Err: "*JMÉNO UMĚLCE JE POVINNÉ",
    btn1: "POTVRDIT",
    btn2: "VYBRAT",
    input2: "NÁZEV TRACK",
    input2Err: "*NÁZEV TRACKU JE POVINNÝ",
    text10: "Hlasujete pro:",
    text11: "PŘIDAT DALŠÍ TRACK",
    input3: "VAŠE JMÉNO",
    input3Err: "*JMÉNO JE POVINNÉ",
    input3Err2: "*VYBER ALESPOŇ JEDEN TRACK",
    input3Err3: "*MŮŽETE HLASOVAT MAXIMÁLNĚ PRO 10 D&B TRACKŮ",
    input4: "VÁŠ EMAIL",
    input4Err: "*EMAIL JE POVINNÝ",
    input4Err2: "*TOHLE NEVYPADÁ JAKO PLATNÝ EMAIL",
    input4Err3: "*V TÉTO ANKETĚ JSTE UŽ HLASOVALI",
    text12: "Souhlasím s odběrem Let It Roll newsletteru.",
    text13: "ODESLAT",
    text14: "ODESÍLÁM...",
    text14_1: "Můžete vybrat až 10 drum & bass tracků vydaných v roce 2024.",

    //SECOND ROUND
    text15: "Immortalz,",
    text16: "Vaše hlasy rozhodly o tom, které tracky postoupily do druhého kola!",
    text17: "Ze všech tracků, pro které jste hlasovali v prvním kole, jsme vybrali 50 těch, které zazněly nejčastěji. Teď je čas, abyste zvolili, jakých 10 skladeb rozduní Main Stage spolu s novou a velkolepou AV show, která proběhne v sobotu od 22:00. ",  
    text18: "Ve druhém kole hlasování můžete jednoduše zaškrtnout až 10 tracků, které formovaly váš rok 2023!",
    text19: "Hlasování bude uzavřeno 24. května a svým hlasem se automaticky zapojujete do slosování o jednu z deseti vstupenek na letní festival Let It Roll.",
    text20: "Hlasováním se zároveň zapojíte do slosování o jednu z deseti vstupenek na letní festival Let It Roll.",
    text21: "Které skladby se vyšplhají do první desítky? Je to pouze ve vašich rukou, Immortalz! ",
    text22: "*tracky jsou seřazené v náhodném pořadí",

    // COUNTDOWN
    text23: "Jste připraveni hlasovat pro TOP 10 drum and bass skladeb roku 2024? Je na čase!",
    text24: "TOP 10 je zpět! Poslední den Let It Roll 2025 se rozloučíme 30minutovou ukázkou nejpůsobivějších drum and bass skladeb roku, tak jak hlasovali naši fanoušci – Immortalz. Je to oslava skladeb, které definovaly rok 2024, která vyvrcholí velkolepou AV show s ohňostrojem na hlavní stagi.",
    text25: "Pojďme znovu vytvořit další nezapomenutelný okamžik, Immortalz, v našem novém domově!",
    text26: "dny",
    text27: "hod",
}


// FOOTER
export const EnFooter = {
    id: "en",
    logoLink: "https://www.letitroll.eu/",
    link1Text: "Rules for Visitors",
    link1: "https://www.letitroll.eu/rules-for-visitors",
    link2Text: "Payment Plan Terms",
    link2: "https://www.letitroll.eu/ticket-in-istallments",
    link3Text: "Privacy Policy",
    link3: "https://www.letitroll.eu/consent-to-receiveing-newsletter",
    link4Text: "Terms & Conditions",
    link4: "https://www.letitroll.eu/terms-conditions",
}


export const CzFooter = {
    id: "cz",
    logoLink: "https://www.letitroll.cz/",
    link1Text: "Privacy Policy",
    link1: "https://www.letitroll.cz/privacy-policy",
    link2Text: "Terms & Conditions",
    link2: "https://www.letitroll.cz/terms-conditions",
    link3Text: "Zasady zpracovani os. udaju",
    link3: "https://www.letitroll.cz/consent-to-receiveing-newsletter",
    link4Text: "Nákup na splátky",
    link4: "https://www.letitroll.cz/ticket-in-istallments",
}




//  THANKS PAGE
export const EnNewsletterLanguage = {
    id: "en",
    text1: "Thank you for your vote!",
    text2: "With it, we’re one step closer to new and exciting experiences!",
    text3: "The second round of voting will take place from 23rd of April to 8th of May.. We’ll keep you informed well in advance so you can cast your vote again!",
    text4: "We’re eagerly looking forward to reuniting at Let It Roll after a year apart!",

    // second round
    text5: "Thank you, Immortalz!",
    text6: "Your votes have been counted, and you've helped shape the new TOP10 show of Let It Roll 2024. With your input, we're one step closer to creating unforgettable memories together!",
    text7: "The results will be unveiled live at Let It Roll 2024, during a brand new festival show on Saturday at 10:00 p.m at the Main Stage.",
    text8: "We can't wait to see you at Let It Roll, where we'll dance, celebrate, and create unforgettable memories together once again!"
}


export const CzNewsletterLanguage = {
    id:"cz",
    text1: "Děkujeme za tvůj hlas!",
    text2: "Díky němu jsme blíž dalším a novým zážitkům!",
    text3: "Druhé kolo hlasováníbude probíhat od 23. dubna do 8. května. Dáme ti vědět včas, ať můžeš hlasovat znovu!",
    text4: "Nemůžeme se dočkat, až se po roce znovu uvidíme na Let It Rollu!",

    // second round
    text5: "Děkujeme!",
    text6: "Tvůj hlas byl započítán!. Opět jsme o krok blíž k novým zážitkům, Immortalz!",
    text7: "Vyhlášení top 10 vítězných tracků proběhne přímo na Let It Rollu 2024 prostřednictvím nové festivalové show v sobotu na Main stage od 22:00.",
    text8: "Nemůžeme se dočkat, až se po roce znovu uvidíme na Let It Rollu!"
}