
// PICTURES
import logo1 from "../pictures/logo3.png"

// VIDEO
import videoBackground from "../video/video1.mp4";
import videoBackgroundPic from "../pictures/video_background.jpg";

export default function ThanksNewsletter({ logoUrl, logoText }) {

    return(
        <>
        <div className="video-container">
            <video 
            autoPlay={true} 
            loop={true}
            controls={false} 
            playsInline
            muted
            src={videoBackground} 
            style={{
                backgroundImage: "url(" + videoBackgroundPic + ")"  
            }}
            type="video/mp4" 
            >
            </video>
        </div>

        <div className='flex-col align-center'
        style={{
            paddingLeft: "var(--gap2)",
            paddingRight: "var(--gap2)",
            paddingTop: "120px",
            paddingBottom: "120px"
        }}
        >  
          <div
          style={{
            maxWidth: "var(--maxWidth)",
            width: "100%",
            zIndex: "20",
            //background: "orange"
          }}>



            <section>
                <div className="flex-col white-text">
                    <div className="flex-col align-center gap-2">

                        <div className="headerImg">
                            <img 
                            src={logo1}
                            alt="top 100 dnb"
                            ></img>
                        </div>

                        <div className="headerContainer">
                            <div className="flex-col ct_bold gap-1">
                            <h1>Hey Immortal,</h1>
                            <h2>Thank you for your vote! With it, we’re one step closer to new and exciting experiences! Imagine the energy as the TOP 10 tracks you chose thunder through the Main Stage in an exclusive show, you are part of!</h2>
                            </div>
                        </div>

                        <div className="flex-col width-full ct_bold gap-1">

                        </div>

                        <div className="flex-col width-full ct_light gap-2">
                            <p className="ct_light">Your voice also enters into an <strong>exclusive draw for a chance to win tickets to Let It Roll 2025.</strong></p>
                        </div>

                        <div className="flex-col width-full ct_light gap-05 pcPad">
                            <p className="ct_bold">Stay tuned for the second round of voting!</p>
                            <p className="ct_light">We can’t wait to reunite and celebrate the love of Drum & Bass together! </p>

                       
                            <a href={logoUrl} >
                                <div className='flex-col mt-3'>
                                    <button 
                                    className='grad red width-fit'
                                    >{logoText}</button>
                                </div>
                            </a>
                        </div> 
                    </div>
                </div>
            </section>
          </div>
        </div>
        </>
    )
}