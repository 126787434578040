
// PICTURES
import deleteImg from "../pictures/delete4.svg"

export default function Votes({
  language, vote, setVote, setFirstVisibility,
  setSecondVisibility, setNameErr, mainAnketaRef
}) {


  return (
    <>
    <h2 className='ct_bold' style={{color: "white", marginBottom: "10px"}}>{language.text10}</h2>

    <div className='flex-col gap-2'>
        {vote.map((thisVote, index) => {
            return(
                <div 
                key={thisVote.track + index}
                className="flex-row space-between gap-2 align-center">
                    <div className='flex-row gap-2 align-center'>
                        <p className='white-text ct_light'>{index + 1}</p>
                        {thisVote.img === null ? <div style={{width: "70px", height: "70px", background: "white"}}></div> : <img style={{width: "70px", height: "70px"}} src={thisVote.img} alt={thisVote.track}></img>}
                    
                        <div className='flex-col gap-05'>
                            <p className='truncate-2 ct_bold' style={{color: "white"}}>{thisVote.track} </p>
                            <p className='truncate-1 ct_light' style={{color: "white"}}>{thisVote.artists.map(e => e.name).join(" & ")} </p>
                        </div>
    
                    </div>
    
                    <button 
                    className='grad2 flex-col align-center justify-center white'
                    onClick={() => {
                        setVote(vote.filter(item => item.track !== thisVote.track))
                    }}
                    ><img width={25} src={deleteImg} alt='delete'></img></button>
                </div>
            )
        })}
    </div>


    <button 
    className='mt-2 grad red'
    //disabled={true}
    onClick={() => {

        if(vote.length < 10) {
            // SHOW AND HIDE TABLES
            setFirstVisibility({
                visible: true
            })

            setSecondVisibility({
                visible: false
            })



            setNameErr && setNameErr("");



            mainAnketaRef.current.scrollIntoView();
        } else{
          setNameErr && setNameErr(language.input3Err3)
        }



    }}
    >{language.text11}</button>
    </>
  )
}