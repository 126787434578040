import { useState, useLayoutEffect } from "react";

export default function Countdown({ language, setIsCountVisible }) {



  const [counterData, setCounterData] = useState({
    days: 13,
    hours: 8,
    minutes: 8,
    seconds: 8
  })

  useLayoutEffect(() => {
    const targetDate = new Date(2025, 3, 4, 0, 0, 0, 0);
    const interval = setInterval(() => {
      const thisTime = Date.now();
      const timeLeft = Math.round((targetDate - thisTime) / 1000);

      // Convert seconds into days, hours, minutes, seconds
      const days = String(Math.floor(timeLeft / (60 * 60 * 24))).padStart(2, '0');
      const hours = String(Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60))).padStart(2, '0');
      const minutes = String(Math.floor((timeLeft % (60 * 60)) / 60)).padStart(2, '0');
      const seconds = String(timeLeft % 60).padStart(2, '0');

      setCounterData({
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      })

      if(timeLeft < 0) {
        setIsCountVisible(false);
      }

    }, 100)

    return() => {
      clearInterval(interval)
    }
  }, [])


  return (
    <div className="flex-row gap-1" >
      <div className="counterContainer">
        <p className="counterNumber">{counterData.days}</p>
        <p className="counterLabel">{language.text26}</p>
      </div>

      <div className="counterContainer">
        <p className="counterNumber">{counterData.hours}</p>
        <p className="counterLabel">{language.text27}</p>
      </div>
      
      <div className="counterContainer">
        <p className="counterNumber">{counterData.minutes}</p>
        <p className="counterLabel">min</p>
      </div>

      <div className="counterContainer">
        <p className="counterNumber">{counterData.seconds}</p>
        <p className="counterLabel">sec</p>
      </div>
    </div>

  )
}