

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// FIREBASE DATABASE
import { db } from '../config/firebase';
import { getDocs, collection, addDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

// EMAIL JS
import emailjs from '@emailjs/browser';

// PICTURES
import deleteImg from "../pictures/delete4.svg"

// COMPONENTS
import { apiKeys } from '../config/apiKeys';
import Votes from './Votes';


export default function ContactDetails({ 
    language,
    vote, 
    setVote,
    setFirstVisibility,
    setSecondVisibility,
    mainAnketaRef,
    name, 
    setName,
    email, 
    setEmail,
    subscribe, 
    setSubscribe,
    nameErr, 
    setNameErr,
    emailErr, 
    setEmailErr
}) {
    // ROUTER
    const navigate = useNavigate()

    // OPACITY ON LOAD
    const [opacity, setOpacity] = useState("0");

    const [sendBtn, setSentBtn] = useState({
        text: language.text13,
        disabled: false 
    })


    // SEND THANK YOU EMAIL
    async function sendEmail() {
        
        const formdata = {
          name: name,
          email: email,
        }         

        const enTemplate = "template_ukv0vpu";
        const czTemplate = "template_htnf5kl";
        let thisTemplate;

        if(language.id === "cz") {
            thisTemplate = czTemplate;
        } else{
            thisTemplate = enTemplate;
        }


        await emailjs.send("service_xw8aem9", thisTemplate, formdata, apiKeys.emailjs)
        .then((result) => {

            console.log("EMAIL WAS SENT")

        }, (error) => {
            console.log(error);
        });

    }


    // SENDING TO FIREBASE
    const contentCollectionRef = collection(db, "prvni-kolo-2025");
    const sendVote = async(data) => {

        try {

            // CREATE NEW TIME STAMP
            const date = new Date();
            const fullDate = date.toLocaleDateString() + " / " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

            await addDoc(contentCollectionRef, {
                name: data.name,
                email: data.email,
                subscribe: data.subscribe,
                track: data.track,
                timeStamp: fullDate,
                language: language.id
            });

            await sendEmail();


            setName("");
            setEmail("");

            // SEND BTN BACK TO NORMAL
            setSentBtn({
                text: language.text13,
                disabled: false
            })


            // AFTER SUCCESFULL SEND
            if(language.id === "en") {
                if(data.subscribe === true) {
                    navigate("/en/thanks-newsletter")
                }else{
                    navigate("/en/thanks")
                }
            } else{
                navigate("/cz/dekujeme")
            }


        }catch(err) {
            console.log(err);
        }
    }    



    // Function to search for an email using the Cloud Function
    async function searchByEmail(email) {
        try {
            const response = await fetch(`https://searchbyemail-3yvdbeajra-uc.a.run.app/?email=${email}`);
    
            if (!response.ok) {
                throw new Error('Failed to fetch');
            }
    
            const data = await response.text();
    
            // Do something with the response data
            //console.log(data);

            return data
        } catch (error) {
            console.error('Error searching for email:', error);
        }
    }


    // VALIDATION
    async function sendFun() {
        try{
            let nameValidation = false;
            let emailValidation = false;
    
            // NAME VALIDATION
            if(name === "") {
                setNameErr(language.input3Err);
            }else if(vote.length < 1){
                setNameErr(language.input3Err2);
            }else if(vote.length > 10){
                setNameErr(language.input3Err2);
            }else{
                setNameErr("");
                nameValidation = true;
            }

            
            // EMAIL VALIDATION
            if(email === ""){
                setEmailErr(language.input4Err);
            } else if(email.includes("@") === false || email.includes(".") === false) {
                setEmailErr(language.input4Err2);
            }
            else{
                setEmailErr("");
                emailValidation = true;
            }
    
            // FINAL VALIDATION
            if(nameValidation === true && emailValidation === true) {

                // SEND BTN SENDING...
                setSentBtn({
                    text: language.text14,
                    disabled: true
                })
    
                const alreadyVoted = await searchByEmail(email.toLowerCase());

                if(alreadyVoted === "false"){
                    const sendingData = {
                        name: name,
                        email: email.toLowerCase(),
                        subscribe: subscribe,
                        track: vote,
                    }
        
                    sendVote(sendingData)            
                        
                } else{
                    setEmailErr(language.input4Err3);
                    setEmail("");

                    // SEND BTN BACK TO NORMAL
                    setSentBtn({
                        text: language.text13,
                        disabled: false
                    })
                } 
                
            }
        } catch(err) {
            console.log(err);
        }
    }


    //WAIT TO LOAD ALL IMAGES
    const imageUrls = [deleteImg];
    useEffect(() => {

        const loadImage = (url) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });

        const loadAllImages = async () => {
        try {
            const imagePromises = imageUrls.map(loadImage);
            await Promise.all(imagePromises);
            setOpacity("1");
        } catch (error) {
            console.error('Error loading images:', error);
        }
        };

        loadAllImages();
        // eslint-disable-next-line
    }, []);


    return (

        <div style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            opacity: opacity,
            transition: "0.2s",
            paddingBottom: "100px"

        }}>

            <Votes 
                language={language}
                vote={vote}
                setVote={setVote}
                setFirstVisibility={setFirstVisibility}
                setSecondVisibility={setSecondVisibility}
                setNameErr={setNameErr}
                mainAnketaRef={mainAnketaRef}
            />

            <div 
            className='flex-col'
            style={{
                marginTop: "100px"
            }}>
                <p className='error ct_bold'>{nameErr}</p>

                <label htmlFor='name' hidden>your name</label>
                <input 
                className='grad'
                id='name'
                type="text" 
                value={name} 
                onChange={(e) => {
                    const input = e.target.value;

                    setName(input);
                    setNameErr("");

                }} 
                placeholder={language.input3}
                style={{
                    padding: "20px",
                    border: "none",
                    //borderRadius: "20px",
                }} 
                />
            </div>


            <div 
            style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px"
            }}>
                <p className='error ct_bold'>{emailErr}</p>
                <label htmlFor='email' hidden>your email</label>
                <input 
                className='grad'
                id='email'
                type="text" 
                value={email} 
                onChange={(e) => {
                    const input = e.target.value;

                    setEmail(input);
                    setEmailErr("");

                }} 
                placeholder={language.input4}
                style={{
                    padding: "20px",
                    border: "none",
                    marginBottom: "20px",
                    //marginTop: "20px"
                }} 
                />
            </div>

            <div >
                <div className='flex-row gap-1'
                onClick={() => {setSubscribe(!subscribe)}}
                style={{
                    cursor: "pointer"
                }}>
                    <label htmlFor='subscribed' hidden>newsletter subscribe</label>
                    <input 
                    type='checkbox' 
                    id='subscribed'
                    checked={subscribe} 
                    readOnly={true}
                    ></input>
                    <label className='ct_medium' style={{color: "white"}}>{language.text12}</label>
                </div>
            </div>

            <br/>
            <button className='grad red' onClick={sendFun} disabled={sendBtn.disabled}>{sendBtn.text}</button>

        </div>

    );
    }
