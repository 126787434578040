import React, { useEffect, useRef, useState } from 'react';

// SPOTIFY API LIBRARIE
import SpotifyWebApi from 'spotify-web-api-js';

// CONFIG
import { apiKeys } from '../config/apiKeys';

export default function Search({ 
    firstInputRef,
    searchQuery, 
    setSearchQuery,
    vote,
    setVote,
    setFirstVisibility,
    setSecondVisibility,
    searchResults, 
    setSearchResults, 
    potvrdit1, 
    setPotvrdit1,
    artistsName, 
    setArtistName,
    language,
    mainAnketaRef
}) {
    // REFS
    const secondInputRef = useRef();

    // SPOTIFY API INIT
    const spotifyApi = new SpotifyWebApi();

    // OPACITY ON LOAD
    const [opacity, setOpacity] = useState("0");

    const [firstLoad, setFirstLoad] = useState(false);

    const [customArtis, setCustomArtist] = useState(false);
    const [artistSelected, setArtistSelected] = useState(false)
    const [artistInfo, setArtistInfo] = useState({})
    const [artistNameSearchResults, setArtistNameSearchResults] = useState([]);
    const [artistRoll, setArtistRoll] = useState({
        height: "0px",
        marginBottom: "0px"
    });

    const [artistErr, setArtistErr] = useState("");
    const [trackError, setTrackError] = useState("");
    const [trackRoll, setTrackRoll] = useState("0px");

    const maxArtistResults = 20;
    const seerchedGenres = [
        "drum and bass", "dnb", "deep rai", "dancefloor dnb", "liquid funk", "halftime dnb", 
        "neurofunk", "darkstep", "jump up", "deep dnb", "uk dnb", "dutch dnb", "jazzy dnb",
        "liquid dnb", "rave"
    ]
    
    // ARTIST SEARCH
    const artistSearch = async (searchQuery) => {
        try {

        // SEARCHING LOOP
        const lopps = 2

        // CAPITALIZE FIRST LETTER
        function capitalizeFirstLetter(str) {
            const words = str.split(" ");
            return words.map(function(word, index) {
              if (index === 0) {
                return word.charAt(0).toUpperCase() + word.slice(1);
              } else {
                return word;
              }
            }).join(" ");
        }

        const capitalized = capitalizeFirstLetter(searchQuery);
        
        // Search for the artist
        let allResults = [];
        for(let i = 0; i < lopps; i++) {
            const thisSearch = await spotifyApi.searchArtists(capitalized , { limit: 50, offset: i * 50 });

            // PUSH THIS ITEM TO ALL RESULTES
            thisSearch.artists.items.forEach(item => {
                allResults.push(item)
            })
        }

        // REMOVE SOME BIG COMPILATIONS
        const artists = allResults.filter(e => e.name.length < 30);


        // 1st CHECK ARTIST GENRE
        const filteredResults = artists.filter(result => {

            if(result.genres.length > 0) {
                // for each genre element
                const checkIfAnyGenreArr = result.genres.filter(genre => {
                    // check if this genre mach any search genres
                    const checkThis = seerchedGenres.filter(thisgenre => genre.includes(thisgenre) === true)

                    return checkThis.length > 0;
                })

                return checkIfAnyGenreArr.length > 0;
            }
        })


        // SHOW THE REST OUT OF GENRES
        const theRestOfGenreArtists = artists.filter(artist => {

            const checkArr = filteredResults.map(art => {
                return artist.id === art.id
            }).filter(item => item === true)

            return checkArr.length === 0
        })


        // 2nd FILTER JUST REALTIVE TO SEARCH (INPUT WORDS)
        // lower case all
        const lowercaseQuery = searchQuery.toLowerCase();
        // odstraní vše kromě abecedy
        const words = lowercaseQuery.split(/\W+/).join("");

        // filtering
        const relativeArtists = filteredResults.filter(item => {
            return item.name.toLowerCase().split(/\W+/).join("").includes(words) === true
        })

        // sorting
        const sortedArtists = relativeArtists.sort((a, b) => {
            return a.name.toLowerCase().split(/\W+/).join("").length - b.name.toLowerCase().split(/\W+/).join("").length
        })



        // SHOW THE REST
        const theRestOfArtists = filteredResults.filter(artist => {

            const checkArr = sortedArtists.map(art => {
                return artist.id === art.id
            }).filter(item => item === true)

            return checkArr.length === 0
        })


        /*
        // NEW SEARCH LOGIC
        const words2 = lowercaseQuery.split(/\W+/);
        
        // CHEK IF THERE ARE SOME INCLUDES FROM SEARCH IF YEAS ADD SEARCH INDEX (HOW MANY HITS)
        const searchNames = artists.map(e => {
            const checkForSearched = words2.map(i => e.name.toLowerCase().includes(i)).filter(item => item === true);    //[true, true]

            return {...e, searchIndex: checkForSearched.length}
        })

        const filteredSearchNames = searchNames.filter(i => i.searchIndex !== 0)
        const filteredSearchNamesSort = filteredSearchNames.sort((a, b) => b.searchIndex - a.searchIndex)

        console.log("NEW ARTISTS")
        console.log(filteredSearchNamesSort)
        */


        // JOIN ALL ARRAYS TOGETHER AND MAX RESULTS
        const finalArr = sortedArtists.concat(theRestOfArtists, theRestOfGenreArtists).filter((item, index) => index < maxArtistResults);



        // AVOID DUPLICATES
        function removeDuplicates(arr) {
            const uniqueMap = new Map();
            
            // Iterate through the array and store objects with unique ids in the Map
            arr.forEach(obj => {
                uniqueMap.set(obj.id, obj);
            });
            
            // Convert the Map back to an array of unique objects
            const uniqueArray = Array.from(uniqueMap.values());
            
            return uniqueArray;
        }
        const final = removeDuplicates(finalArr);

        // SET FINAL RESULTES TO BE PRINT
        setArtistNameSearchResults(final)

        } catch (error) {
        console.error('Error searching for song:', error);
        }
    };

    // TRACK SEARCH
    const handleSearch = async (searchQuery) => {
        try {
            if(customArtis === false) {
                const albumResults = await spotifyApi.getArtistAlbums(artistInfo.id, {include_groups: "single,appears_on", limit: 50 });
                const allResults = albumResults.items;

                // FILTERING ALL RESULTS BY YEAR
                const filteredResults = allResults.filter(result => 
                    result.release_date.includes("2024-12") === true ||
                    result.release_date.includes("2024-11") === true ||
                    result.release_date.includes("2024-10") === true ||
                    result.release_date.includes("2024-09") === true ||
                    result.release_date.includes("2024-08") === true ||
                    result.release_date.includes("2024-07") === true ||
                    result.release_date.includes("2024-06") === true ||
                    result.release_date.includes("2024-05") === true ||
                    result.release_date.includes("2024-04") === true ||
                    result.release_date.includes("2024-03") === true ||
                    result.release_date.includes("2024-02") === true ||
                    result.release_date.includes("2024-01") === true
                )
                    
                // AVOID DUPLICATES
                function removeDuplicates(arr) {
                    const uniqueMap = new Map();
                    
                    // Iterate through the array and store objects with unique ids in the Map
                    arr.forEach(obj => {
                        uniqueMap.set(obj.id, obj);
                    });
                    
                    // Convert the Map back to an array of unique objects
                    const uniqueArray = Array.from(uniqueMap.values());
                    
                    return uniqueArray;
                }
                const final = removeDuplicates(filteredResults);

                // FILTER JUST REALTIVE TO SEARCH
                if(searchQuery?.length > 0) {
                    // vyhledavani na lowercase
                    const lowercaseQuery = searchQuery?.toLowerCase();
                    
                    // odstraní vše kromě abecedy
                    const words = lowercaseQuery?.split(/\W+/).join("");

                    // filtering
                    const relativeTracks = final.filter(item => {
                        return item.name.toLowerCase().split(/\W+/).join("").includes(words) === true
                    })
                    
                    // sorting
                    const sortedTracks = relativeTracks.sort((a, b) => {
                        return a.name.toLowerCase().split(/\W+/).join("").length - b.name.toLowerCase().split(/\W+/).join("").length
                    })
                    
                    setSearchResults(sortedTracks);

                } else{
                    setSearchResults(final);
                }
            } else{

            }
        } catch (error) {
        console.error('Error searching for song:', error);
        }
    };
    
    // VALIDATION
    function validation(result, custom) {

        if(custom === true){
            let artistValidation = false;
            let trackValidation = false;

            if(artistsName.length < 1) {
                setArtistErr(language.input1Err);
            } else{
                artistValidation = true;
            }

            if(searchQuery.length < 1) {
                setTrackError(language.input2Err)
            } else{
                trackValidation = true;
            }

            if(artistValidation === true && trackValidation === true) {

                const checkVotes = vote.filter(item => item.track === result.name);

                if( checkVotes.length === 0 ) {
                    setVote([...vote, {
                        track: result.name,
                        id: null,
                        artists: result.artists,
                        url: null,
                        custom_search: custom,
                        img: null
                    }])
                }
        
                setFirstVisibility({
                    visible: false
                })
        
                setSecondVisibility({
                    visible: true
                })

                // SET DEFAULTS
                setArtistName("");
                setSearchQuery("")
                setPotvrdit1({
                    transform: "translateX(0px)",
                    marginRight: "70px"
                })
            }

        } else{
            const checkVotes = vote.filter(item => item.track === result.name);

            if( checkVotes.length === 0 ) {
                setVote([...vote, {
                    track: result.name,
                    id: result.id,
                    artists: result.artists,
                    url: result.external_urls.spotify,
                    custom_search: custom,
                    img: result.images?.[2].url
                }])
            }

            setFirstVisibility({
                visible: false
            })
    
            setSecondVisibility({
                visible: true
            })

            // SET DEFAULTS
            setArtistName("");
            setSearchQuery("")
            setSearchResults([]);
            setPotvrdit1({
                transform: "translateX(0px)",
                marginRight: "70px"
            })
        }
    }

    // Search function
    function searchArtist(e) {
        const input = e.target.value;

        setArtistName(input);
        setPotvrdit1({
            transform: "translateX(0px)",
            marginRight: "70px"
        })

        setSearchResults([]);
        setArtistErr("");

        if(input.length !== 0) {
            artistSearch(input)
        } else{
            setArtistNameSearchResults([]);
        }   

        mainAnketaRef.current.scrollIntoView();
    }


    // LOAD HANDLE SEARCH ONLY WHEN ARTIST IS SELECTED
    useEffect(() => {
        if(firstLoad === true) {
            handleSearch()
        }
        
        //on first load
        setFirstLoad(true);

    // eslint-disable-next-line    
    }, [artistSelected])


    // GET SPOTIFY API ACCES TOKEN
    useEffect(() => {

        const getSpotifyToken = async () => {
            try {

            // GET RANDOM SPOTIFY API KEYs
            const thisApikey = apiKeys.spotifyKeys[Math.floor(Math.random() * (apiKeys.spotifyKeys.length - 0.1))]; 
            //console.log(thisApikey.id)


            // Fetch access token using your API key
            const response = await fetch('https://accounts.spotify.com/api/token', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                grant_type: 'client_credentials',
                client_id: thisApikey.spotifyApiId,
                client_secret: thisApikey.spotifyApiSecret ,
                }),
            });
            const { access_token } = await response.json();

            // Set access token for Spotify Web API
            spotifyApi.setAccessToken(access_token);


            } catch (error) {
            console.error('Error searching for song:', error);
            }
        };
        getSpotifyToken();

    // eslint-disable-next-line
    }, [])


    // ARTIST ROLL
    useEffect(() => {
        if(artistNameSearchResults.length > 0) {
            setArtistRoll({
                height: "500px",
                marginBottom: "20px"
            });
        } else{
            setArtistRoll({
                height: "0px",
                marginBottom: "0px"
            });
        }
    }, [artistNameSearchResults])

    // TRACK ROLL
    useEffect(() => {
        if(searchResults.length > 0) {
            setTrackRoll("500px");
        } else{
            setTrackRoll("0px");
        }
    }, [searchResults])


    // TRANSITION ON LOAD
    useEffect(() => {
        setOpacity("1");
    }, [])

    return (
    <div style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        //background: "orange",
        position: "relative",
        //zIndex: firstVisibility.zIndex,
        opacity: opacity,
        transition: "0.2s",      
        overflowX: "hidden",  

    }}>
        <p className='error ct_bold'>{language.text14_1}</p>

        <p className='error ct_bold'>{artistErr}</p>

        <div
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "5px",
            marginBottom: "20px",
            width: "100%",
            //gap: "10px"
            //background: "orange"
            overflow: "hidden",
            //position: "relative"
        }}>
            <label htmlFor='artist' hidden>artist name</label>
            <input 
                className='grad'
                id='artist'
                style={{
                    width: "100%",
                    marginRight: potvrdit1.marginRight,
                    transition: "0.5s"
                }}
                type="text" 
                value={artistsName} 
                onChange={searchArtist} 
                placeholder={language.input1} 

            />

            <div
            style={{
                transform: potvrdit1.transform,
                transition: "0.5s",
                position: "absolute",

                right: "0px",
                objectPosition: "cover"
            }}
            >
                <button 
                className="grad-confirm red"
                style={{

                }}
                onClick={() => {
                    if(artistsName.length > 0){
                        setArtistNameSearchResults([]);

                        setPotvrdit1({
                            transform: "translateX(var(--confirmBtnLength))",
                            marginRight: "0px"
                        })
        
                        setCustomArtist(true);
                    } else{
                        // SCROLL TO THE TOP
                        mainAnketaRef.current.scrollIntoView();
                        setArtistErr(language.input1Err);
                    }
                }}
                >{language.btn1}</button>
            </div>
        </div>









        <div 
        style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxHeight: artistRoll.height,
            width: "100%",
            overflowY: "scroll",
            transition: "0.5s",
            marginBottom: artistRoll.marginBottom
        }}>

            {artistNameSearchResults.map(( result ) => {
            return (
                <div 
                key={result.id}
                className=''
                style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                //borderRadius: "20px",
                marginRight: "10px",
                gap: "10px",
                padding: "10px",
                background: "rgba(215, 215, 215, 1)"
                }}>
                    <div className='flex-row gap-1 align-center'>
                        {result.images[2] 
                        ? <img src={result.images[2]?.url} alt={result.name} style={{ width: 70, height: 70}} /> 
                        : <div style={{width: "70px", height: "70px", background: "white"}}></div>}
                        
                        <p className='ct_bold'>{result.name}</p>
                    </div>

                    <div className='flex-col gap-1 align-end'>
                        <button 
                        className='grad2 gray width-fit'
                        onClick={() => {
                            setArtistName(result.name);
                            setArtistInfo(result);
                            
                            setArtistNameSearchResults([]);
                            setArtistSelected(!artistSelected);
                            
                            setPotvrdit1({
                                transform: "translateX(var(--confirmBtnLength))",
                                marginRight: "0px",
                            })

                            setCustomArtist(false);

                        }}
                        >{language.btn2}</button>

                    </div>


                </div>
            )})}
        </div>

        <p className='error ct_bold'>{trackError}</p>


        <div 
        style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "5px",
            marginBottom: "20px",
            width: "100%",
            //gap: "10px"
            //background: "orange",
            overflow: "hidden",
            //position: "relative"
        }}>
            <label htmlFor='track' hidden>track</label>
            <input 
                className='grad'
                id='tack'
                style={{
                    width: "100%",
                    marginRight: "70px",
                    transition: "1s"
                }}
                type="text" 
                value={searchQuery} 
                onChange={(e) => {
                    const input = e.target.value;
    
                    setSearchQuery(input);
                    setTrackError("")
                    handleSearch(input);
    
                    mainAnketaRef.current.scrollIntoView();
                }} 
                placeholder={language.input2} 

            />

            <div
            style={{
                transform: "translateX(0px)",
                transition: "1s",
                opacity: "1",
                position: "absolute",
                right: "0px",
            }}
            >
                <button 
                className="grad-confirm red"
                onClick={() => {
                    //setSearchResults([]);
       
                    const thisResults = {
                       name: searchQuery,
                       artists: [{name: artistsName}],
                    }
       
                    validation(thisResults, true)
       
                   // SCROLL TO THE TOP
                   mainAnketaRef.current.scrollIntoView();
                }}
                >{language.btn1}</button>
            </div>
        </div>





        <div 
        style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxHeight: trackRoll,
            width: "100%",
            overflowY: "scroll",
            transition: "0.5s"
        }}>

            {searchResults.map(( result ) => {
            return (
                <div 
                key={result.id}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginRight: "10px",
                    gap: "10px",
                    padding: "10px",
                    background: "rgba(215, 215, 215, 1)"
                }}
                    >

                    <div className='flex-row gap-1 align-center'>
                        {result.images[2] 
                        ? <img src={result.images[2]?.url} alt={result.name} style={{ width: 70, height: 70 }} /> 
                        : <div style={{width: "70px", height: "70px", background: "white"}}></div>}

                        <div className='flex-col gap-05'>
                            <p className='ct_bold'>{result.name}</p>
                            <p className='ct_light' style={{fontSize:"13px"}}>{result.artists.map(artist => artist.name).join(', ')}</p>
                        </div>
                    </div>




                    <div className='flex-col gap-1 align-end'>
                        <button 
                        className='grad2 gray width-fit'
                        onClick={() => {
                            validation(result, false);
                            setTrackError("");

                            // SCROLL TO THE TOP
                            mainAnketaRef.current.scrollIntoView();
                        }}
                        >{language.btn2}</button>
                    </div>



                </div>
            )})}
        </div>


    </div>
  );
}
