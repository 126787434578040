import logo1 from "../pictures/logo3.png"

export default function Header({ language }) {


    return(
        <>
        <section>
            <div className="flex-col white-text">
                <div className="flex-col align-center gap-3">
                    <div className="headerImg">
                        <img 
                        src={logo1}
                        alt="top 100 dnb"
                        ></img>
                    </div> 

                    <div className="headerContainer">
                        <div className="flex-col ct_bold gap-1">
                            <h1 >{language.text1}</h1>
                            <h2>{language.text2}</h2>
                        </div>
                    </div>

                    <div className="flex-col width-full ct_light">
                        <p className="ct_medium"><strong>{language.text3}</strong></p>
                        <p className="pl-2">{language.text4}</p>
                    </div>

                    <div className="flex-col width-full ct_light">
                        <p className="ct_bold">{language.text5}</p>
                        <p className="pl-2">{language.text6}</p>
                    </div>

                    <div className="flex-col width-full ct_light">
                        <p
                        style={{
                            fontStyle: 'italic'
                        }}
                        >{language.text7}</p>
                    </div>

                    <div className="flex-col width-full ct_bold">
                        <p><strong>{language.text8}</strong></p>
                    </div>

                    <div className="flex-col width-full ct_light">
                        <p>{language.text9}</p>
                    </div>

                </div>

            </div>
        </section>
        </>
    )
}