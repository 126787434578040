import { useEffect, useRef, useState } from 'react';

// VIDEO
import videoBackground from "../video/video1.mp4";
import videoBackgroundPic from "../pictures/video_background.jpg";

// COMPONENTS
import Header from './Header';
import ContactDetails from './ContactDetails';
import Search from './Search';

export default function MainPage({ language }) {

  // REFS
  const firstInputRef = useRef();
  const mainAnketaRef = useRef();

  // SEARCHING STATES
  const [artistsName, setArtistName] = useState(""); 
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // CONTACT STATES
  const [name, setName] = useState('');

  const [email, setEmail] = useState('');
  const [subscribe, setSubscribe] = useState(false);
  const [nameErr, setNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("")

  // STEPS VISIBILITY
  const [firstVisibility, setFirstVisibility] = useState({
    visible: true
  })

  const [secondVisibility, setSecondVisibility] = useState({
    visible: false
  })

  // VOTE DATA
  const [vote, setVote] = useState([])

  // CONFIRM FOR ARTIST
  const [potvrdit1, setPotvrdit1] = useState({
    transform: "translateX(0px)",
    marginRight: "70px"
  })

  const videoRef = useRef()

  // PLAY AFTER LOAD
  useEffect(() => {
    videoRef.current.play()
  }, [])

  return (

    
      <div className='flex-col align-center'
      style={{
        paddingLeft: "var(--gap2)",
        paddingRight: "var(--gap2)",
      }}
      >
        <div className="video-container">
          <video 
          ref={videoRef}
          //autoPlay={true} 
          loop={true}
          controls={false} 
          playsInline
          muted
          src={videoBackground} 
          style={{
              backgroundImage: "url(" + videoBackgroundPic + ")"  
          }}
          type="video/mp4" 
          >
          </video>
        </div>

        <div
        style={{
          maxWidth: "var(--maxWidth)",
          width: "100%",
          zIndex: "10",
          marginBottom: "50px"
        }}
        >        
          <Header language={language}/>

          <div 
          ref={mainAnketaRef} 
          className='scrollMarginTop-1'
          style={{
              marginTop: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              minHeight: "700px",
              paddingBottom: "100px"
          }}>
            {firstVisibility.visible === true 
            ? <Search 
            firstInputRef={firstInputRef}
            artistsName={artistsName} 
            setArtistName={setArtistName} 
            searchQuery={searchQuery} 
            setSearchQuery={setSearchQuery}
            vote={vote}
            setVote={setVote} 
            setFirstVisibility={setFirstVisibility}
            setSecondVisibility={setSecondVisibility} 
            searchResults={searchResults} 
            setSearchResults={setSearchResults} 
            potvrdit1={potvrdit1} 
            setPotvrdit1={setPotvrdit1} 
            language={language} 
            mainAnketaRef={mainAnketaRef}
            />
            : null }


            {secondVisibility.visible === true 
            ? <ContactDetails 
            language={language}
            vote={vote}
            setVote={setVote}
            setFirstVisibility={setFirstVisibility}
            setSecondVisibility={setSecondVisibility}
            mainAnketaRef={mainAnketaRef} 
            name={name} 
            setName={setName}
            email={email} 
            setEmail={setEmail}
            subscribe={subscribe}
            setSubscribe={setSubscribe}
            nameErr={nameErr}
            setNameErr={setNameErr}
            emailErr={emailErr}
            setEmailErr={setEmailErr}
            />
            : null }


          </div>

        </div>

      </div>

  );
}