




import { useEffect, useRef } from 'react';

// VIDEO
import videoBackground from "../video/video1.mp4";
import videoBackgroundPic from "../pictures/video_background.jpg";

// PIC
import logo1 from "../pictures/logo3.png"

// COMPONENTS
import Countdown from './Countdown';

export default function MainPageCountdown({
  language, setIsCountVisible
}) {

    // VIDEO
    const videoRef = useRef()

    // PLAY AFTER LOAD
    useEffect(() => {
        videoRef.current.play()
    }, [])

    return (        
        <div className='flex-col align-center'
        style={{
            paddingLeft: "var(--gap2)",
            paddingRight: "var(--gap2)",
        }}
        >
            <div className="video-container">
                <video 
                ref={videoRef}
                loop={true}
                controls={false} 
                playsInline
                muted
                src={videoBackground} 
                style={{
                    backgroundImage: "url(" + videoBackgroundPic + ")"  
                }}
                type="video/mp4" 
                >
                </video>
            </div>

            <div
            style={{
            maxWidth: "var(--maxWidth)",
            width: "100%",
            zIndex: "10",
            marginBottom: "200px"
            }}
            >        
                        <section>
            <div className="flex-col white-text">
                <div className="flex-col align-center gap-3">
                    <div className="headerImg">
                        <img 
                        src={logo1}
                        alt="top 100 dnb"
                        ></img>
                    </div> 
                    
                    <div className="headerContainer">
                        <div className="flex-col gap-1">
                          <h2>
                          {language.text23} 
                          </h2>

                          <p style={{fontWeight: '300'}}>
                            {language.text24}
                          </p>
                        </div>
                    </div>

                    <div className="flex-col width-full ct_light">
                        <p>
                          {language.text25}
                        </p>
                    </div>

                    <Countdown 
                    language={language} 
                    setIsCountVisible={setIsCountVisible}
                    />

                </div>

            </div>
        </section>
           
            </div>
        </div>
    );
}